import _difference from 'lodash.difference'

export default {

  methods: {
    addLocks ({ lockedFields }, ...fields) {
      let lockedSet
      if (!lockedFields) {
        lockedFields = ''
        lockedSet = new Set([...fields])
      } else {
        lockedSet = new Set([...lockedFields.split(','), ...fields])
      }
      return Array.from(lockedSet).join(',')
    },
    removeLocks ({ lockedFields }, ...fields) {
      if (lockedFields) {
        let lockedArray = _difference(lockedFields.split(','), fields)
        return lockedArray.join(',')
      }
      return ''
    },
    mutateLocks (plan, addOrRemove, ...fields) {
      if (addOrRemove === 'add') {
        plan.lockedFields = this.addLocks(plan, ...fields)
      } else if (addOrRemove === 'remove') {
        plan.lockedFields = this.removeLocks(plan, ...fields)
      }
    }
  }
}
